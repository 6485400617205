.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
/*
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/

.card-collection{
  display: flex;
  flex-direction: right;
}

@media screen and (max-width: 575px) {
  .card-collection{
    flex-direction: column;
    width: 100%;
    align-content: center;
    flex-wrap:  wrap;
    justify-content: center;
  }

}
#logo-cjl {
  width:  180px;
}

.standout {
  box-shadow: rgba(0,0,0,0.16) 0px 1px 4px, rgb(51,51,51) 0px 0px 0px 3px;
}



.card-img-top{
  height: 200px;
  width: auto;
  object-fit: contain;
}

.card{
  width: 18rem;
}

#wa {
  width: 200px;
  margin-bottom: 20px;
}



